import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from 'app/entities/event/event.types';

export type SportTypeStr =
  | 'RHYTHMIC_GYMNASTICS'
  | 'AGG'
  | 'TANVO'
  | 'LUMO'
  | 'AKRO'
  | 'MAG'
  | 'WAG'
  | 'KA'
  | 'TUM'
  | 'TG'
  | 'STARA'
  | 'TUL_DANCE'
  | 'DANCE_LUMO'
  | 'GFL'
  | 'ANY'
  | 'DANCE';

export enum SportType {
  RHYTHMIC_GYMNASTICS = 'RHYTHMIC_GYMNASTICS',
  AGG = 'AGG',
  TANVO = 'TANVO',
  LUMO = 'LUMO',
  AKRO = 'AKRO',
  MAG = 'MAG',
  WAG = 'WAG',
  KA = 'KA',
  TUM = 'TUM',
  TG = 'TG',
  STARA = 'STARA',
  TUL_DANCE = 'TUL_DANCE',
  DANCE_LUMO = 'DANCE_LUMO',
  GFL = 'GFL',
  ANY = 'ANY',
  DANCE = 'DANCE',
}

const RG_APPARATUS_BY_FINAL_CATEGORY_TYPE: Readonly<Record<string, string>> = {
  RG_AA_FINAL_BALL: 'BALL',
  RG_AA_FINAL_CLUBS: 'CLUBS',
  RG_AA_FINAL_ROPE: 'ROPE',
  RG_AA_FINAL_HOOP: 'HOOP',
  RG_AA_FINAL_RIBBON: 'RIBBON',
  RG_AA_FINAL_3_RIBBONS_2_BALLS: '3_RIBBONS_2_BALLS',
};

export const CategoryTypePerformance = 'PERFORMANCE';

export const WAG_APPARATUSES: readonly string[] = ['FLOOR', 'VAULT', 'BEAM', 'BARS'];
export const MAG_APPARATUSES: readonly string[] = ['FLOOR', 'VAULT', 'HORSE', 'H_BAR', 'P_BARS', 'RINGS'];
export const RG_APPARATUSES: readonly string[] = ['ROPE', 'HOOP', 'BALL', 'CLUBS', 'RIBBON'];
export const STARA_RG_APPARATUSES: readonly string[] = ['FREE', 'ROPE', 'HOOP', 'BALL', 'CLUBS', 'RIBBON'];

const SPORTS_WITH_INTERNAL_RESULTS = ['RHYTHMIC_GYMNASTICS', 'AGG', 'TANVO', 'LUMO', 'AKRO', 'DANCE_LUMO', 'GFL'];
const SPORTS_WITH_EXTERNAL_RESULTS = ['MAG', 'WAG', 'KA', 'TG'];

@Injectable({ providedIn: 'root' })
export class SportService {
  private sportTypeData: Record<string, any> = {};
  private judgingRuleProfilesData: Record<string, any> = {};

  constructor(private http: HttpClient) {}

  loadData() {
    console.log('Loading sport data');
    const p1 = this.http
      .get<Record<string, any>>('/api/public/disciplines')
      .toPromise()
      .then((response) => (this.sportTypeData = response));

    const p2 = this.http
      .get<Record<string, any>>('/api/public/judging-rules')
      .toPromise()
      .then((response) => (this.judgingRuleProfilesData = response));

    return Promise.all([p1, p2]);
  }

  types() {
    return this.sportTypeData;
  }

  getCategoryAttribute<T>(category: Category, attrName: string, defaultValue: T = undefined): T {
    if (category.categoryDefinitionOverride) {
      const overridenValue = category.categoryDefinitionOverride[attrName];
      if (overridenValue !== undefined) {
        return overridenValue;
      }
    }
    return this.getSportCategoryAttribute(category.sportType, category.categoryCode, attrName, defaultValue);
  }

  getSubCategoryAttribute(category: Category, subCategoryId: string, attrName: string, defaultValue: any) {
    const subCategories =
      category.categoryDefinitionOverride?.subCategories ||
      this.getSportCategoryAttribute(category.sportType, category.categoryCode, 'subCategories');
    const subCategory = subCategories?.find((subCat) => subCat.id === subCategoryId);
    return (subCategory && subCategory[attrName]) || defaultValue;
  }

  getSportCategoryAttribute(sportType: string, categoryCode: string, attrName: string, defaultValue: any = undefined) {
    const sportDefinition = this.sportTypeData[sportType];
    const categoryDefinition = sportDefinition?.categories[categoryCode] || {};
    if (categoryDefinition[attrName] !== undefined) {
      return categoryDefinition[attrName];
    }
    if (sportDefinition && sportDefinition[attrName] !== undefined) {
      return sportDefinition[attrName];
    }
    return defaultValue;
  }

  getMinAge(category: Category, defaultValue?: number) {
    return this.getCategoryAttribute(category, 'minAge', defaultValue);
  }

  getMaxAge(category: Category, defaultValue?: number) {
    return this.getCategoryAttribute(category, 'maxAge', defaultValue);
  }

  getSportCategoryRequiredLicense(sportType: SportType, categoryCode: string) {
    return this.getSportCategoryAttribute(sportType, categoryCode, 'license');
  }

  getCategoryRequiredLicense(category: Category) {
    return this.getSportCategoryRequiredLicense(category.sportType, category.categoryCode);
  }

  wagApparatuses() {
    return wagApparatuses();
  }

  magApparatuses() {
    return magApparatuses();
  }

  rgApparatuses() {
    return rgApparatuses();
  }

  staraRgApparatuses() {
    return STARA_RG_APPARATUSES.slice();
  }

  disciplineIds(): string[] {
    return Object.keys(this.sportTypeData).filter((key) => !key.startsWith('$'));
  }

  getCategoryDefinition(category: Category): any {
    const sportDef = this.sportTypeData[category.sportType];
    let mergedDefinition = {};
    if (sportDef) {
      mergedDefinition = {
        ...mergedDefinition,
        ...this.sportTypeData[category.sportType].categories[category.categoryCode],
      };
      if (category.categoryDefinitionOverride) {
        mergedDefinition = { ...mergedDefinition, ...category.categoryDefinitionOverride };
      }
    }
    return mergedDefinition;
  }

  judgingRuleProfiles() {
    return this.judgingRuleProfilesData;
  }

  sortByJudgeCode(sportType, elements, judgeCodeAttrNameOrExtractor) {
    const judgeCodesOrder = this.types()[sportType].judgeOrder || [];
    const indexByJudgeCode = {};
    judgeCodesOrder.forEach(function (judgeCode, index) {
      indexByJudgeCode[judgeCode] = index;
    });
    elements.sort((e1, e2) => {
      const judgeCode1 = this.extractValue(e1, judgeCodeAttrNameOrExtractor);
      const judgeCode2 = this.extractValue(e2, judgeCodeAttrNameOrExtractor);
      const comparableValue1 = indexByJudgeCode[judgeCode1] !== undefined ? indexByJudgeCode[judgeCode1] : judgeCode1;
      const comparableValue2 = indexByJudgeCode[judgeCode2] !== undefined ? indexByJudgeCode[judgeCode2] : judgeCode2;
      if (comparableValue1 < comparableValue2) return -1;
      if (comparableValue1 > comparableValue2) return 1;
      return 0;
    });
  }

  categoriesWithResults(categories: Category[]): Category[] {
    return categories.filter((cat) => {
      return (
        SPORTS_WITH_INTERNAL_RESULTS.includes(cat.sportType) || SPORTS_WITH_EXTERNAL_RESULTS.includes(cat.sportType)
      );
    });
  }

  categoriesWithInternalResults(categories: Category[]): Category[] {
    return categories.filter((cat) => SPORTS_WITH_INTERNAL_RESULTS.includes(cat.sportType));
  }

  categoriesWithExternalResults(categories: Category[]): Category[] {
    return categories.filter((cat) => SPORTS_WITH_EXTERNAL_RESULTS.includes(cat.sportType));
  }

  supportsInternalResults(sportType: SportTypeStr | SportType): boolean {
    return SPORTS_WITH_INTERNAL_RESULTS.includes(sportType);
  }

  getJudgingRulesProfileAttribute(category: Category, attrName: string, defaultValue?) {
    const rulesOverrides = category.judgingRulesOverride;
    if (rulesOverrides?.[attrName] !== undefined) {
      return rulesOverrides[attrName];
    }
    const profile = this.judgingRuleProfilesData[category.judgingRulesProfileId];
    return profile && profile[attrName] !== undefined ? profile[attrName] : defaultValue;
  }

  judgeTypesForCategory(category: Category) {
    if (category.sportType === 'AGG') {
      const rulesVersion = this.judgingRuleProfilesData[category.judgingRulesProfileId].rulesVersion;
      if (rulesVersion === 'V_2023') {
        return AGG_JUDGE_TYPES_2023;
      }
    }
    return JUDGE_TYPES_BY_SPORT[category.sportType];
  }

  isIndividualSport(category: Category, subcategory?) {
    if (!category || category.type === CategoryTypePerformance) return false;
    const disciplineDef = this.types()[category.sportType];
    const categoryDef = this.getCategoryDefinition(category);
    if (!categoryDef.subCategories) {
      return !disciplineDef.team && !categoryDef.team;
    } else {
      return subcategory?.type === 'INDIVIDUAL';
    }
  }

  isTeamSport(category: Category, subcategory?) {
    if (!category || category.type === CategoryTypePerformance) return false;
    const disciplineDef = this.types()[category.sportType];
    const categoryDef = this.getCategoryDefinition(category);
    if (!categoryDef.subCategories) {
      return !!(disciplineDef.team || categoryDef.team);
    } else {
      return subcategory?.type === 'TEAM';
    }
  }

  getRgProgramTypesForCategory(category: Category): string[] {
    const finalProgramType = RG_APPARATUS_BY_FINAL_CATEGORY_TYPE[category.type];
    return finalProgramType ? [finalProgramType] : this.getCategoryAttribute(category, 'programs');
  }

  private extractValue(element, attrNameOrExtractor) {
    if (!element) return element;
    if (typeof attrNameOrExtractor === 'function') {
      return attrNameOrExtractor(element);
    }
    return element[attrNameOrExtractor];
  }
}

export function wagApparatuses() {
  return WAG_APPARATUSES.slice();
}

export function magApparatuses() {
  return MAG_APPARATUSES.slice();
}

export function rgApparatuses() {
  return RG_APPARATUSES.slice();
}

const JUDGE_TYPES_BY_SPORT = {
  RHYTHMIC_GYMNASTICS: [
    { type: 'D', codes: ['D1', 'D2', 'D3', 'D4'] },
    { type: 'DB', codes: ['DB1', 'DB2'] },
    { type: 'DA', codes: ['DA1', 'DA2'] },
    { type: 'E', codes: ['E1', 'E2', 'E3', 'E4', 'E5', 'E6'] },
    { type: 'A', codes: ['A1', 'A2', 'A3', 'A4'] },
  ],
  AGG: [
    { type: 'TV', codes: ['TV1', 'TV2', 'TV3', 'TV4'] },
    { type: 'AV', codes: ['AV1', 'AV2', 'AV3', 'AV4'] },
    { type: 'EXE', codes: ['EXE1', 'EXE2', 'EXE3', 'EXE4'] },
  ],
};
const AGG_JUDGE_TYPES_2023 = [
  { type: 'TV', codes: ['TV1', 'TV2', 'TV3', 'TV4'] },
  { type: 'AV-A', codes: ['AV-A1', 'AV-A2', 'AV-A3', 'AV-A4'] },
  { type: 'AV-B', codes: ['AV-B1', 'AV-B2', 'AV-B3', 'AV-B4'] },
  { type: 'EXE', codes: ['EXE1', 'EXE2', 'EXE3', 'EXE4'] },
];

const SPORTS_WITH_SCORING = ['RHYTHMIC_GYMNASTICS', 'AGG', 'AKRO', 'TANVO', 'LUMO', 'DANCE_LUMO', 'GFL'];

export function scoringSupported(sportType: string) {
  return SPORTS_WITH_SCORING.includes(sportType);
}
